export default {
  butt: {
    hand: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "Que te introduzca el dedo en el ano conlleva MUY POCO riesgo de contraer el VIH."
            }
          }
        }
      }
    },
    mouth: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "El sexo oral en tu ano (getting a rim job) conlleva MUY POCO riesgo de contraer el VIH."
            }
          }
        }
      }
    },
    penis: {
      condom: {
        null: {
          null: {
            null: {
              value: 39.0,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, tu pareja tiene una carga viral detectable y usas condones consistentemente. ¡Buen trabajo por usar condones consistentemente! Sin embargo, la carga viral detectable de tu pareja aún puede ponerte a riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia"
            },
            sti: {
              value: 102.0,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable y usas condones consistentemente. ¡Buen trabajo por usar condones consistentemente! Sin embargo, esto aún te pone en ALTO riesgo de contraer el VIH porque la carga viral detectable de tu pareja más ciertos ITS pueden aumentar el riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia"
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, tu pareja tiene una carga viral indetectable y usas condones. ¡Buen trabajo por usar condones! ¡Usar condones consistentemente y tener un compañero que es indetectable reduce tu riego de contraer el VIH bastante! Sin embargo, no puedes controlar la carga viral de tu pareja, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral indetectable y usas condones consistentemente. Usar condones consistentemente y tener una pareja con una carga viral indetectable reduce tu riesgo de contraer el VIH bastante. ¡Buen trabajo! Sin embargo, no puedes controlar la carga viral de tu pareja, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        },
        prep: {
          null: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, tu pareja tiene una carga viral detectable, tomas PrEP y usas condones. ¡Buen trabajo por usar condones consistentemente! ¡Usar dos capas de protección reduce tu riesgo de contraer el VIH bastante! Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia"
            },
            sti: {
              value: 1.0,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable, tomas PrEP y usas condones consistentemente. ¡Buen trabajo! ¡Usar dos capas de protección reduce tu riesgo de contraer el VIH bastante! Sin embargo, ciertas ITS pueden aumentar tu riesgo de contraer el VIH. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, tu pareja tiene una carga viral indetectable, tomas PrEP y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Estás haciendo todo lo que puedes para proteger tu salud sexual! Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral indetectable, tomas PrEP y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Estás haciendo bastante para proteger tu salud sexual! Sin embargo, ciertas ITS pueden aumentar el riesgo de contraer el VIH. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        }
      },
      null: {
        null: {
          null: {
            null: {
              value: 138.0,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, tu pareja tiene una carga viral detectable y no usas protección.  No usar protección te pone en ALTO riesgo de contraer el VIH porque es más fácil que el virus entre a tu cuerpo y te infecte si no estás usando protección. Además, la carga viral detectable de tu pareja puede aumentar tu riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 366.0,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable y no usas protección. Esto te pone en el riesgo MÁS ALTO de contraer el VIH porque es más fácil que el virus entre a tu cuerpo y te infecte si no usas protección. Además, la carga viral detectable de tu pareja más ciertos ITS pueden aumentar tu riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL y tu pareja tiene una carga viral indetectable. El medicamento que toma tu pareja VIH-positiva evita que se transmita el VIH. Sin embargo, no puedes controlar la carga viral de tu pareja, así que siempre usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, uno de ustedes tiene una ITS y tu pareja tiene una carga viral indetectable. Esto te pone a riesgo de contraer el VIH. El medicamento que toma tu pareja VIH-positiva evita que se transmita el VIH.  Sin embargo, no puedes controlar la carga viral de tu pareja y ciertas ITS pueden aumentar el riego de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        },
        prep: {
          null: {
            null: {
              value: 1.0,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, tu pareja tiene una carga viral detectable y tomas PrEP. ¡Buen trabajo por tomar PrEP! Sin embargo, la carga viral detectable de tu pareja aún puede ponerte a riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 4.0,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable y tomas PrEP. ¡Buen trabajo por tomar PrEP! Sin embargo, la carga viral detectable de tu pareja más ciertos ITS pueden ponerte en riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, tu pareja tiene una carga viral indetectable y tomas PrEP. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! Tomar PrEP y tener una pareja con una carga viral indetectable reduce tu riesgo de contraer el VIH. Sin embargo, no puedes controlar la carga viral de tu pareja, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja PASIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral indetectable y tomas PrEP. Tomar PrEP y tener una pareja con una carga viral indetectable reduce tu riesgo de contraer VIH bastante. ¡Buen trabajo! Sin embargo, no puedes controlar la carga viral de tu pareja y ciertas ITS pueden aumentar el riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        }
      }
    }
  },
  hand: {
    butt: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "Introducir tu dedo en el ano de tu pareja conlleva MUY POCO riesgo de contraer el VIH."
            }
          }
        }
      }
    },
    hand: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "Tocar, agarrar las manos o acariciar no te pone en riesgo de contraer el VIH. ¡Trata otra combinación!"
            }
          }
        }
      }
    },
    penis: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "Masturbar a tu pareja conlleva MUY POCO riesgo de contraer el VIH."
            }
          }
        }
      }
    },
    vagina: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "Introducir el dedo en la vagina de tu pareja conlleva MUY POCO riesgo de contraer el VIH."
            }
          }
        }
      }
    }
  },
  mouth: {
    butt: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "El sexo oral en el ano de tu pareja (giving a rim job) conlleva MUY POCO riesgo de contraer el VIH."
            }
          }
        }
      }
    },
    mouth: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "Besar no te pone en riesgo de contraer el VIH. ¡Trata otra combinación!"
            }
          }
        }
      }
    },
    penis: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "El sexo oral en el pene de tu pareja (giving a blowjob) conlleva MUY POCO riesgo de contraer el VIH."
            }
          }
        }
      }
    },
    vagina: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "El sexo oral en la vagina de tu pareja conlleva MUY POCO riesgo de contraer el VIH."
            }
          }
        }
      }
    }
  },
  penis: {
    butt: {
      condom: {
        null: {
          null: {
            null: {
              value: 4.0,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, tu pareja tiene una carga viral detectable y usas condones consistentemente. ¡Buen trabajo por usar condones consistentemente! Sin embargo, la carga viral detectable de tu pareja aún te puede poner a riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 11.0,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable y usas condones consistentemente. ¡Buen trabajo por usar condones consistentemente! Pero, la carga viral detectable de tu pareja más ciertos ITS pueden ponerte en riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia"
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, tu pareja tiene una carga viral indetectable y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! Usar condones y tener una pareja con una carga viral indetectable reduce tu riego de contraer el VIH bastante. Sin embargo, no puedes controlar la carga viral de tu pareja, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral indetectable y usas condones. Esto conlleva un riesgo BAJO de contraer el VIH. Usar condones consistentemente y tener una pareja con una carga viral indetectable reduce tu riesgo de contraer el VIH bastante. ¡Buen trabajo! Sin embargo, no puedes controlar la carga viral de tu pareja, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        },
        prep: {
          null: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, tu pareja tiene una carga viral detectable, tomas PrEP y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Usar dos capas de protección reduce tu riesgo de contraer el VIH bastante! Añade o elimina factores para ver cómo tu riesgo de contraer VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable, tomas PrEP y usas condones consistentemente. ¡Buen trabajo! ¡Usar dos capas de protección reduce tu riesgo de contraer el VIH bastante! Sin embargo, ciertas ITS pueden aumentar tu riesgo de contraer el VIH. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, tu pareja tiene una carga viral indetectable, tomas PrEP y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Estás haciendo todo lo que puedes para proteger tu salud sexual! Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral indetectable, tomas PrEP y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer VIH. ¡Buen trabajo! ¡Estás haciendo bastante para proteger tu salud sexual! Sin embargo, ciertas ITS pueden aumentar el riesgo de contraer el VIH. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        }
      },
      null: {
        null: {
          null: {
            null: {
              value: 11.0,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, tu pareja tiene una carga viral detectable y no usas protección.  No usar protección te pone a riesgo de contraer VIH porque es más fácil que el virus entre a tu cuerpo y te infecte. Además, la carga viral de tu pareja puede aumentar tu riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 29.0,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable y no usas protección. No usar protección te pone a riesgo de contraer el VIH porque es más fácil que el virus entre al cuerpo y te infecte. Además, la carga viral detectable de tu pareja puede aumentar tu riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL y tu pareja tiene una carga viral indetectable. Esto conlleva un riesgo BAJO de contraer el VIH. El medicamento que toma tu pareja VIH-positiva evita que se transmita el VIH. Sin embargo, no puedes controlar la carga viral de tu pareja, así que siempre usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, uno de ustedes tiene una ITS y tu pareja tiene una carga viral indetectable. El medicamento que toma tu pareja evita que se transmita el VIH.  Sin embargo, no puedes controlar la carga viral de tu pareja y ciertas ITS pueden aumentar el riego de contraer VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        },
        prep: {
          null: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, tu pareja tiene una carga viral detectable y tomas PrEP. ¡Buen trabajo por tomar PrEP! Sin embargo, la carga viral de tu pareja aún puede ponerte a riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable y tomas PrEP. ¡Buen trabajo tomando PrEP! Sin embargo, la carga viral detectable más ciertos ITS pueden ponerte en riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, tu pareja tiene una carga viral indetectable y tomas PrEP. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Tomar PrEP y tener una pareja con una carga viral indetectable reduce tu riesgo de contraer el VIH bastante! Sin embargo, no puedes controlar la carga viral de tu pareja, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo ANAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral indetectable y tomas PrEP. Esto conlleva un riesgo BAJO de contraer VIH. Tomar PrEP y tener una pareja con una carga viral indetectable reduce tu riesgo de contraer el VIH bastante. ¡Buen trabajo! Sin embargo, no puedes controlar la carga viral de tu pareja y ciertas ITS pueden aumentar el riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        }
      }
    },
    hand: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "Que otro te masturbe conlleva MUY POCO riesgo de contraer el VIH,"
            }
          }
        }
      }
    },
    mouth: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "El sexo oral en el pene (blowjob) conlleva MUY POCO riesgo de contraer el VIH."
            }
          }
        }
      }
    },
    penis: {
      null: {
        null: {
          null: {
            null: {
              value: 0.0,
              explain:
                "Frotar tu pene con el pene de tu pareja (grinding) conlleva MUY POCO riesgo de contraer el VIH."
            }
          }
        }
      }
    },
    vagina: {
      condom: {
        null: {
          null: {
            null: {
              value: 1.0,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, tu pareja tiene una carga viral detectable y usas condones consistentemente. ¡Buen trabajo por usar condones! Sin embargo, la carga viral detectable de tu pareja aún te puede poner a riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 2.0,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable y usas condones. ¡Buen trabajo por usar condones consistentemente! Sin embargo, la carga viral detectable de tu pareja más ciertos ITS pueden ponerte en riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, tu pareja tiene una carga viral indetectable y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Usar condones y tener una pareja que es indetectable reduce tu riesgo de contraer VIH bastante! Sin embargo, no puedes controlar la carga viral de tu pareja, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral indetectable y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer VIH. ¡Usar condones y tener una pareja con una carga viral indetectable reduce tu riesgo de contraer el VIH bastante! ¡Buen trabajo! Sin embargo, no puedes controlar la carga viral de tu pareja y ciertas ITS pueden aumentar el riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        },
        prep: {
          null: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, tu pareja tiene una carga viral detectable, tomas PrEP y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Usar dos capas de protección reduce el riesgo de contraer el VIH bastante!  Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable, tomas PrEP y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Usar dos capas de protección reduce tu riesgo de contraer el VIH bastante! Sin embargo, ciertas ITS pueden aumentar tu riesgo de contraer el VIH. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, tu pareja tiene una carga viral indetectable, tomas PrEP y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Estás haciendo todo lo que puedes para proteger tu salud sexual! Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral indetectable, tomas PrEP y usas condones consistentemente. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Estás haciendo mucho para proteger tu salud sexual! Sin embargo, ciertas ITS pueden aumentar el riesgo de contraer el VIH. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        }
      },
      null: {
        null: {
          null: {
            null: {
              value: 4.0,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, tu pareja tiene una carga viral detectable y no usas protección.  No usar protección te pone a riesgo de contraer el VIH porque es más fácil que el virus entre a tu cuerpo y te infecte. Además, la carga viral de tu pareja puede aumentar tu riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 11.0,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable y no usas protección. No usar protección te pone a riesgo de contraer VIH porque es más fácil que el virus entre al cuerpo y te infecte. Además, la carga viral detectable de tu pareja puede aumentar tu riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL y tu pareja tiene una carga viral indetectable. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! El medicamento que toma tu pareja VIH-positiva evita que el VIH se transmita. Pero no puedes controlar la carga viral de tu pareja, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, uno de ustedes tiene una ITS y tu pareja tiene una carga viral indetectable. Esto conlleva un riesgo BAJO de contraer el VIH. El medicamento que toma tu pareja VIH-positiva evita que se transmita el VIH. Sin embargo, no puedes controlar la carga viral de tu pareja y ciertas ITS pueden aumentar tu riego de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        },
        prep: {
          null: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, tu pareja tiene una carga viral detectable y tomas PrEP. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo por tomar PrEP! Sin embargo, la carga viral detectable de tu pareja aún puede ponerte en riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral detectable y tomas PrEP. ¡Buen trabajo por tomar PrEP! Sin embargo, la carga viral detectable de tu pareja más ciertos ITS pueden ponerte en riesgo de contraer el VIH, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          },
          uplus: {
            null: {
              value: 0.5,
              explain:
                "Eres la pareja ACTIVA durante el sexo VAGINAL, tu pareja tiene una carga viral indetectable y tomas PrEP. Esto conlleva un riesgo BAJO de contraer el VIH. ¡Buen trabajo! ¡Tomar PrEP y tener una pareja que es indetectable reduce tu riesgo de contraer el VIH bastante! Sin embargo, no puedes controlar la carga viral de tu pareja, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            },
            sti: {
              value: 0.5,
              explain:
                "ERES la pareja ACTIVA durante el sexo VAGINAL, uno de ustedes tiene una ITS, tu pareja tiene una carga viral indetectable y tomas PrEP. Esto conlleva un riesgo BAJO de contraer VIH. ¡Buen trabajo! Tomar PrEP y tener una pareja con una carga viral indetectable reduce tu riesgo de contraer el VIH bastante. Sin embargo, no puede controlar la carga viral de tu compañero, así que usa más de una forma de protección que esté bajo TU control. Añade o elimina factores para ver cómo tu riesgo de contraer el VIH cambia."
            }
          }
        }
      }
    }
  }
};
